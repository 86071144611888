import styled from "styled-components";

export const consentText = (
  <span>
    Concordo com o armazenamento e processamento dos meus dados para fins
    comunicação e e retorno de contato, conforme descrito em nossa{" "}
    <a
      href="/politica-de-privacidade"
      target="_blank"
      rel="noopener noreferrer"
    >
      Política de Privacidade
    </a>
    .
  </span>
);

// export const consentText =
//   "Ao enviar o formulário, você concorda com o armazenamento e processamento dos seus dados pessoais para fins de elaboração e envio do orçamento solicitado, conforme descrito em nossa";

export const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 400px;
  gap: 10px;

  input {
    border: 1px solid var(--secondary-color);
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 14px;
    background-color: var(--input);
    outline-color: var(--secondary-color);
  }

  .consentBox {
    display: flex;
  }

  .consentBox input[type="checkbox"] {
    width: 15px;
    height: 15px;
    margin-top: 1px;
  }

  /* .consentBox input[type="checkbox"]:checked + label.text {
    font-weight: 500;
    color: green;
  } */

  .consentBox label {
    margin-left: 8px;
    font-size: 14px;
    text-align: justify;
  }

  .consentBox input[type="checkbox"]:checked + label {
    font-weight: 500;
  }

  a {
    text-decoration: underline;
  }

  a:hover {
    font-weight: bold;
    transition: ease-in-out 0.2s;
  }

  button {
    margin-top: 10px;
    width: 100px;
    padding: 8px;
    border-radius: 15px;
    border: solid 1px #000000;
    background-color: var(--text-highlight);
    color: #ffffff;
    font-weight: 300;
    letter-spacing: 1px;
    cursor: pointer;
  }

  button:hover {
    font-weight: 500;
  }

  @media (max-width: 576px) {
    width: 300px;
    align-items: center;

    input {
      width: 100%;
    }
  }
`;

export const TextArea = styled.textarea`
  font-size: 15px;
  height: 100px;
  resize: none;
  border: 1px solid var(--secondary-color);
  outline-color: var(--secondary-color);
  border-radius: 15px;
  padding: 10px 20px;
  background-color: var(--input);
  margin-top: 10px;

  @media (max-width: 576px) {
    width: 100%;
  }
`;
