import { FaCartShopping } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { RootState } from "../../store";

function CartProductsCounter() {
  const cartProducts = useSelector(
    (state: RootState) => state.cart.cartProducts,
  );
  const totalQuantity = cartProducts.reduce(
    (sum, product) => sum + product.quantity,
    0,
  );

  return (
    <BudgetCart>
      <Link to="/orcamento">
        <FaCartShopping />
        <p>Orçamento</p>
        <span>{totalQuantity}</span>
      </Link>
    </BudgetCart>
  );
}

export default CartProductsCounter;

const BudgetCart = styled.div`
  color: var(--background-button);
  position: relative;
  text-align: center;
  margin-left: 10px;
  :hover {
    color: var(--hover);
    transition: 0.5s ease;
  }
  svg {
    margin-top: 10px;
    font-size: 30px;
  }
  p {
    font-size: 12px;
    font-weight: 500;
    transform: translateY(-50%);
    letter-spacing: 1px;
  }

  span {
    position: absolute;
    top: 4px;
    right: 8px;
    font-size: 12px;
    font-weight: 500;
    background-color: var(--hover);
    width: 16px;
    height: 16px;
    line-height: 17px;
    border-radius: 50%;
    text-align: center;
    display: inline-block;
    color: #ffffff;
  }

  @media (max-width: 960px) {
    svg {
      font-size: 25px;
    }

    p {
      font-size: 10px;
    }
  }
`;
