import styled from "styled-components";
import logo from "../../assets/logo192-small.png";

function Title({ title }: { title: string }) {
  return (
    <TitleBox>
      <TitleStyle className="title-component">
        <Logo src={logo} alt="logo multitec" />
        <PageTitle>{title}</PageTitle>
      </TitleStyle>
      <Underline />
    </TitleBox>
  );
}

export default Title;

const TitleStyle = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 40px 0;
`;

const Logo = styled.img`
  width: 30px;
`;

const PageTitle = styled.h2`
  text-transform: uppercase;
  font-weight: 500;
  color: var(--text-highlight);
  letter-spacing: 5px;
  font-size: 20px;

  @media (max-width: 768px) {
    scale: 0.95;
    text-align: center;
  }
`;

const TitleBox = styled.div`
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const Underline = styled.hr`
  width: 150px;
  display: block;
  margin-left: 50px;
  border-width: 2px;

  @media (max-width: 768px) {
    margin-left: 0;
  }
`;
