import { Link } from "react-router-dom";
import styled from "styled-components";
import data from "../../json/new-products.json";
import Title from "../title";

function NewProducts() {
  return (
    <div>
      <Title title="novidades" />
      <NewProductsDiv>
        {data.products.map((product) => (
          <ProductsCard key={product.id}>
            <Link to={`/produtos/${product.id}`}>
              <img
                src={product.image}
                alt={`foto do produto ${product.name}`}
                loading="lazy"
              />
            </Link>
            <h3>{product.group}</h3>
            <p className="product-subtitle">{product.name}</p>
            <p className="summary">{product.summary}</p>
            <DetailsLink to={`/produtos/${product.id}`}>Saiba mais</DetailsLink>
          </ProductsCard>
        ))}
      </NewProductsDiv>
    </div>
  );
}

export default NewProducts;

const NewProductsDiv = styled.div`
  margin: 20px 50px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-auto-rows: 475px;
  justify-content: space-between;
  gap: 20px;

  @media (max-width: 768px) {
    margin: 20px 5vw;
  }
  @media (max-width: 640px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const ProductsCard = styled.div`
  background-color: var(--background-card);
  border: solid 1px #000000;
  border-radius: 10px;
  max-width: 280px;
  width: 100%;
  min-height: 475px;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin-bottom: 1.5rem;

  img {
    width: 100%;
    height: 220px;
    object-fit: contain;
    border-radius: 10px;
    background-color: #ffffff;
  }

  h3 {
    color: var(--text-highlight);
    text-transform: uppercase;
    font-weight: 500;
    font-size: 0.88rem;
  }

  .product-subtitle {
    font-size: 0.84rem;
    text-transform: uppercase;
    font-weight: 500;
  }

  .summary {
    font-size: 0.88rem;
    font-weight: 300;
    text-align: justify;
    margin: auto 0;
  }
`;

const DetailsLink = styled(Link)`
  background-color: var(--text-highlight);
  color: #ffffff;
  width: 80px;
  padding: 8px 5px;
  border-radius: 8px;
  font-weight: 300;
  font-size: 12px;
  text-align: center;

  &:hover {
    scale: 1.05;
    transition: 0.2s ease-in-out;
  }
`;
