import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
    :root {
        --primary-color: #5f292d;
        --text-highlight: #96383e;
        --secondary-color: #B1B1B1;
        --background: #EAE9E9;
        --hover: #96383Ebf;
        --input:#D9D9D9;
        --background-button: #525357;
        --background-card:#D9D9D9;
    }
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: "Roboto";
    }

    ul {
        list-style: none;
    }

    a {
        text-decoration: none;
        color: inherit;
    }
`;

export const AppContainer = styled.div`
  background-color: var(--background);
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
