import styled from "styled-components";
import Title from "../title";

function Privacy() {
  return (
    <section>
      <Title title="Termos de Privacidade" />
      <PageContent>
        <Text>
          Obrigado por visitar a Multitec!
          <br />
          No momento, nossa Política de Privacidade está sendo desenvolvida para
          garantir que atendamos aos mais altos padrões de proteção de dados e
          conformidade legal.
          <br />
          <br />
          <strong>O que você precisa saber:</strong>
          <br />
          <br />
          <strong>- Coleta de Dados:</strong>Estamos comprometidos em proteger
          suas informações pessoais. Coletamos dados necessários para fornecer e
          melhorar nossos serviços.
          <br />
          <strong>- Uso de Dados:</strong> Suas informações serão usadas apenas
          para os fins para os quais foram coletadas e de acordo com as leis
          aplicáveis.
          <br />
          <strong>- Segurança:</strong> Estamos tomando medidas para proteger
          suas informações contra acesso não autorizado e outros riscos.
          <br />
          <br />
          <strong>Atualizações Futuras:</strong> Estamos trabalhando para
          disponibilizar uma Política de Privacidade completa e detalhada em
          breve. Assim que estiver pronta, a atualizaremos nesta página.
          <br />
          <br />
          <strong>Contato:</strong> Se tiver alguma dúvida ou preocupação sobre
          como estamos lidando com seus dados, não hesite em nos contatar nos
          números ou emails informados{" "}
          <a href="/contato" target="_blank">
            aqui
          </a>
          .
        </Text>
      </PageContent>
    </section>
  );
}

export default Privacy;

const PageContent = styled.div`
  margin: 20px 50px;
`;

const Text = styled.p`
  line-height: 25px;
  text-align: justify;
  font-weight: 300;

  a {
    text-decoration: underline;
  }
`;
