import { Link } from "react-router-dom";
import styled from "styled-components";

import Banner from "../banner";
import Map from "../map-location";
import NewProducts from "../new-products";
import RepresentedCompanies from "../represented-companies";
import Title from "../title";

function Home() {
  const bannerGeral =
    "https://multitecrs.com.br/images/banner/Banner_geral-medium.jpg";
  const bannerEvident =
    "https://multitecrs.com.br/images/banner/Banner_Evident 07-06-24-medium.jpg";
  const bannerLabor2 =
    "https://multitecrs.com.br/images/banner/Banner_Labor-2-medium.jpg";
  const bannerLabor1 =
    "https://multitecrs.com.br/images/banner/Banner_Labor-medium.jpg";
  const bannerLinhaAnalitica =
    "https://multitecrs.com.br/images/banner/Banner_linha_analitica-medium.jpg";
  const bannerPurificadores =
    "https://multitecrs.com.br/images/banner/Banner_linha_purificadores_de_agua-medium.jpg";
  const bannerRegmed =
    "https://multitecrs.com.br/images/banner/Banner_site_Regmed-medium.jpg";
  const bannerLinhaAgricola =
    "https://multitecrs.com.br/images/banner/Banner_site_linha_agricola-medium.jpg";
  const bannerControleQualidade =
    "https://multitecrs.com.br/images/banner/banner_site_linha_controle_qualidade-medium.jpg";
  const bannerPesagem =
    "https://multitecrs.com.br/images/banner/Banner_site_linha_pesagem-medium.jpg";

  const images = [
    { src: bannerGeral, path: "/produtos" },
    { src: bannerLinhaAgricola, path: "/" },
    { src: bannerControleQualidade, path: "/" },
    { src: bannerPesagem, path: "/balanças" },
    { src: bannerLinhaAnalitica, path: "/" },
    { src: bannerPurificadores, path: "/produtosBusca?search=purificador" },
    { src: bannerLabor1, path: "/" },
    { src: bannerLabor2, path: "/" },
    { src: bannerEvident, path: "/microscópios" },
    { src: bannerRegmed, path: "/" },
  ];
  const autoChangeInterval = 5000;

  return (
    <>
      <Banner images={images} autoChangeInterval={autoChangeInterval} />

      <NewProducts />
      <RepresentedCompanies />

      <div>
        <Title title="notícias" />

        <Link to="/noticias">
          <NewsDiv>
            <p>
              Clique aqui e acesse nosso portal de notícias e comunicados
              importantes
            </p>
            {/* <MainNewsDiv><img src={NewsImg} alt="teste" /></MainNewsDiv> */}
          </NewsDiv>
        </Link>
      </div>

      <Map />
    </>
  );
}

export default Home;

const NewsDiv = styled.div`
  margin: 20px 50px;
  background-color: var(--background-card);
  border: solid 1px #000000;
  border-radius: 10px;
  padding: 15px;
  letter-spacing: 2px;
  text-align: center;
  line-height: 25px;
`;

// const MainNewsDiv = styled.div`
//   display: flex;
//   justify-content: center;
//   margin: 10px;

//   img {
//     border-radius: 10px;
//     max-width: 100%;
//   }
// `;
