import { Link } from "react-router-dom";
import styled from "styled-components";
import olympus from "../../assets/companies/Evident_Olympus-medium.jpg";
import labor from "../../assets/companies/Labor.jpg";
import gehaka from "../../assets/companies/gehaka.jpg";
import regmed from "../../assets/companies/regmed-medium.jpg";
import Title from "../title";

function RepresentedCompanies() {
  return (
    <div>
      <Title title="representadas" />
      <RepresentedDiv>
        <Link to="https://www.laborestabille.com.br/" target="_blank">
          <img src={labor} alt="logo laborestabille" />
        </Link>
        <Link to="https://www.gehaka.com.br/" target="_blank">
          <img src={gehaka} alt="logo gehaka" />
        </Link>
        <Link to="https://www.olympus-ims.com/pt/" target="_blank">
          <img src={olympus} alt="logo olympus" />
        </Link>
        <Link to="https://www.regmed.com.br/" target="_blank">
          <img src={regmed} alt="logo regmed" />
        </Link>
      </RepresentedDiv>
    </div>
  );
}

export default RepresentedCompanies;

const RepresentedDiv = styled.div`
  margin: 20px 50px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 15px;

  img {
    width: 180px;
    height: 150px;
    border: solid 1px var(--primary-color);
    border-radius: 10px;
    object-fit: contain;
    padding: 2px;
    background-color: #ffffff;
  }

  @media (max-width: 768px) {
    justify-content: center;
  }
`;
